import React, {useEffect, useId, useReducer, useRef, useState} from 'react';
import logo from './logo.svg';
import './App.css';
import User from './classes'
import Home from './pages/Home'
import {Route,Routes,BrowserRouter as Router,Outlet} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'

const App :React.FC = () => {


    return (
      <Router>
          <Routes>

          <Route element={<Home/>} path='/'/>

          </Routes>
          <Outlet/>
      </Router>
  );
}

export default App;
