import React from 'react'
import Video from '../component/video'

function Home() {

return(
    <div style={{height:'100vh',background:'#f3e9b8'}} className={"d-flex justify-content-center align-items-center"}>
        <div  style={{width:'60vw'}}>
        <Video source="/assets/video.mp4" />
        </div>
    </div>
)
}

export  default  Home