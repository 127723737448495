import React, {Component, useEffect, useRef,useState} from 'react'
import '../styles/bar.css'





interface Props{
    Icon?:any
    currentTime:any
    duration:any
    onChange?:any

}



function Bar({Icon=null,currentTime,duration,onChange}:Props){

    const inputRef = useRef<HTMLInputElement>(null)
    const [down,setdown] = useState<boolean>(false);
    useEffect(()=>{

            setRange()
    },[currentTime,duration])

    function setRange(){
        let percent =  (currentTime/duration)*100 +"%";

        if(!inputRef.current )return

        inputRef.current.style.cssText = "--size:"+percent+';'

    }

    function mouseDown(e:any){
        if(!inputRef.current)return
        let slider = inputRef.current;
        let{left}=slider.getBoundingClientRect()
       let getValueX = e.clientX-left;
        let percent = (getValueX / e.target.offsetWidth)*100

        setdown(true)
        onChange({value:percent})
    }
    function mouseUp(){
        setdown(false);
    }


    function over(e:any){
        if(!inputRef.current)return
        inputRef.current.classList.remove('hide')
    }
    function leave(e:any){
        if(!inputRef.current)return
        inputRef.current.classList.add('hide')
    }
    return(
        <>

            {Icon&&<Icon style={{color:'#fff',fontSize:'18px'}} />}
            <input onMouseDown={mouseDown} onMouseUp={mouseUp} onMouseMove={(e:any)=>down&&onChange({value:e.target.value})} ref={inputRef} onMouseLeave={leave} onMouseOver={over} className={"bar hide"} type={'range'} />

            </>
    )
}

export default  Bar