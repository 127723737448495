import React, {MutableRefObject} from 'react'
import '../styles/video.css'
import Bar from './Bar'
import BarVolume from './BarVolume'

import FullscreenIcon from '@mui/icons-material/Fullscreen';

interface  stateFace{
    playing:Boolean,
    duration:number,
    currentTime:number,
    volume:number
}
interface props {
    source:string
}
export default class Video extends React.Component<props,stateFace>{
    state:stateFace={
        playing:false,
        duration:0,
        currentTime:0,
        volume:100
    }
    private videoRef: React.RefObject<HTMLVideoElement>;
    private containerRef:React.RefObject<HTMLDivElement>;
    constructor(props:props) {
        super(props);
        this.containerRef = React.createRef<HTMLDivElement>()
        this.videoRef = React.createRef<HTMLVideoElement>();
    }
    componentDidMount() {
        if(!this.videoRef.current)return
        this.videoRef.current.addEventListener('timeupdate',(e)=>this.setTime(e))
        this.videoRef.current.addEventListener('loadeddata',(e:any)=>this.setState({...this.state,duration:e.target.duration}))
        this.videoRef.current.addEventListener('volumechange',(e:any)=>{
            this.setState({...this.state,volume:e.target.volume})
        })


    }

    fullScreen() {
        if (!this.containerRef.current) return
        if (!document.fullscreen) {
            this.containerRef.current.requestFullscreen();
        } else {
            document.exitFullscreen()
        }
    }
    togglePlay(self:any){

    if(self.videoRef?.current?.paused){
        self.videoRef?.current?.play()
    }else{
        self?.videoRef?.current?.pause();
    }

    }
    setTime(e:any){
        if(!this.videoRef.current)return
        this.setState({...this.state,currentTime:e.target.currentTime,duration:this.videoRef.current.duration})
    }
    changeRange(e:any){

        if(!this.videoRef.current)return
        // this.videoRef.current.muted=true

        let value= e.value;
        this.videoRef.current.removeEventListener('timeupdate',this.setTime)
        this.videoRef.current.currentTime = (value/100) * this.state.duration


    }

changeVolume({value}:any){

if(!this.videoRef.current)return
    this.videoRef.current.volume = value/100;
}

    render(){

        return(
            <div ref={this.containerRef} className="custom-video">

                <video  onPlay={(e)=>e.preventDefault()} onClick={(e)=>{this.togglePlay(this)}} ref={this.videoRef} src={this.props.source} />
                <div className={"container-toolbar d-flex flex-column justify-content-end"}>
                  <Bar  onChange={(e:any)=>this.changeRange(e)} currentTime={this.state.currentTime} duration={this.state.duration} />
                   <div   className={"d-flex toolbar w-100  justify-content-between"}>

                       <div className={'d-flex align-items-center p-2'}>
                        <BarVolume volume={this.state.volume} onChange={(data:any)=>this.changeVolume(data)} />

                    </div>
                       <FullscreenIcon onClick={()=>this.fullScreen()} style={{color:'#fff'}}/>
                   </div>

                </div>

            </div>
        )
    }
}