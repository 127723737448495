import React, {Component, useEffect, useRef,useState} from 'react'
import '../styles/bar.css'
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import {VolumeDown} from "@mui/icons-material";

interface Props{
  onChange:any,
    volume:number
}



function BarVolume({onChange,volume}:Props){

    const inputRef = useRef<HTMLInputElement>(null)
    const [down,setdown] = useState<boolean>(false);
    const [muted,setMute] = useState<boolean>(false);

    useEffect(()=>{
        if(inputRef.current){
            setRange(volume);
        }
    },[])


    function setRange(percent:number){
       if(!inputRef.current)return

        inputRef.current.style.cssText='--volumeSize:'+percent+'%'
    }


    function mouseDown(e:any){
        if(!inputRef.current)return
        let slider = inputRef.current;
        let{left}=slider.getBoundingClientRect()
        let getValueX = e.clientX-left;
        let percent = (getValueX / e.target.offsetWidth)*100
        setRange(percent)
        setdown(true)
        onChange({value:percent})

    }
    function mouseUp(){
        setdown(false);
    }

    function mouseMove(e:any){
       if(down){
           onChange({value:Number(e.target.value)});
             setRange(e.target.value)
       }
    }

    function over(e:any){
        if(!inputRef.current)return
        inputRef.current.classList.remove('hide')
    }
    function leave(e:any){
        if(!inputRef.current)return
        inputRef.current.classList.add('hide')
    }

    function toggleMute(){
        if(!muted){
            setRange(0);
            onChange({value:0})
            setMute(true)
            return
        }

        setRange(100);
        onChange({value:100})
        setMute(false)
    }

    function MuteIcon(){


        if( volume > 0){
            return  (
            <VolumeUpIcon onClick={toggleMute} style={{color:'#fff',fontSize:'18px'}} />
            )
    }

       return  (<VolumeOffIcon onClick={toggleMute} style={{color:'#fff',fontSize:'18px'}} />)

}

    return(
        <>

            <MuteIcon/>
            <input onMouseDown={mouseDown} onMouseUp={mouseUp} onMouseMove={mouseMove} ref={inputRef} onMouseLeave={leave} onMouseOver={over} className={"barVolume ms-2 hide"} type={'range'} />

        </>
    )
}

export default  BarVolume